import React, { useState, useMemo, useEffect } from "react";
import { 
    Modal, 
    Typography, 
    Select, 
    Input, 
    Row, 
    Col, 
    List, 
    Card, 
    Button,
    Checkbox
} from 'antd';
import { 
    DatabaseOutlined, 
    LoadingOutlined, 
    MergeOutlined, 
    ProfileOutlined,
    PlusOutlined
} from '@ant-design/icons';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextArea from "antd/es/input/TextArea";
import { fetchWorksheetData } from "../../endpoints/fetchWorksheetData";


const enrichmentConfigs = {
    associate: {
        name: 'AI Match',
        description: 'Match rows to rows in another worksheet using AI',
        category: 'AI',
        column_name: 'Matched Values',
        column_type: 'text',
    },
    product_id: {
        name: 'Match Product',
        description: 'Match a description or product code column against your product master list',
        column_name: 'Product',
        column_type: 'product',
        category: 'AI'
    },
    cross_reference: {
        name: 'Value Lookup',
        description: 'Add data from another worsheet by value exact match',
        category: 'Cross Reference'
    },
};

// Map category to a relevant icon
const categoryIcons = {
    'Normalize': <ProfileOutlined />,
    'Cross Reference': <MergeOutlined />,
    'AI': <FontAwesomeIcon icon={faWandMagicSparkles} />,
};

const EnrichmentFlow = ({ userData, open, onClose, onOk, columns, currentWorksheetId }) => {
    const [selectedCategory, setSelectedCategory] = useState('Match Against');
    const [selectedType, setSelectedType] = useState(null);
    // Replaced single selectedColumn with array of columns:
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [newColumnName, setNewColumnName] = useState('');
    const [newPrompt, setNewPrompt] = useState('');
    const [selectedWorksheet, setSelectedWorksheet] = useState(null);
    const [worksheetOptions, setWorksheetOptions] = useState([]);
    const [worksheetColumnOptions, setWorksheetColumnOptions] = useState([]);
    const [selectedWorksheetColumn, setSelectedWorksheetColumn] = useState(null);
    const [leaveBlank, setLeaveBlank] = useState(false);

    // Group configs by category
    const categories = useMemo(() => {
        const temp = {};
        Object.entries(enrichmentConfigs).forEach(([key, val]) => {
            if (!temp[val.category]) temp[val.category] = [];
            temp[val.category].push({ type: key, ...val });
        });
        // Return as an array to easily map over
        return Object.entries(temp).map(([catName, items]) => ({
            catName,
            items
        }));
    }, []);

    // Whenever user picks an enrichment type, set the default "new column name" from the config
    const handleSelectType = (typeKey) => {
        setSelectedType(typeKey);
        setNewColumnName(enrichmentConfigs[typeKey].column_name || '');
        // reset columns each time user changes type
        setSelectedColumns([]);
        setSelectedWorksheet(null);
        setSelectedWorksheetColumn(null);
        setNewPrompt('');
        setLeaveBlank(false);
    };

    // Trigger onOk with chosen type, columns, plus the config
    const handleOk = () => {
        let selectedFolder = '';
        if (selectedWorksheet) {
            const selectedSheetData = worksheetOptions.find((o) => o.value === selectedWorksheet);
            selectedFolder = selectedSheetData?.folder || '';
        }

        onOk(
            selectedType, 
            // Pass the full array of columns now
            selectedColumns, 
            {
                ...enrichmentConfigs[selectedType],
                // Use whatever new column name the user typed in
                column_name: newColumnName,
                // This is now an array
                inputCols: selectedColumns,
                // selectedWorksheetS3Key -> "`folder`/`worksheet_id`.json"
                selectedWorksheetS3Key: (selectedFolder ? `${selectedFolder}/${selectedWorksheet}.json` : `${selectedWorksheet}.json`),
                selectedWorksheetColumn: selectedWorksheetColumn,
                newPrompt: newPrompt,
                leaveBlank: leaveBlank
            }
        );
    };

    // Compute if "Ok" button should be disabled (all required selects must be filled)
    const isOkDisabled = useMemo(() => {
        // Must have a type
        if (!selectedType) return true;

        // Must have at least one selected column and none of them can be empty
        if (selectedColumns.length === 0) return true;
        if (selectedColumns.some(col => !col)) return true;

        // For "associate", selectedWorksheet and selectedWorksheetColumn are also required
        if (selectedType === 'associate') {
            if (!selectedWorksheet) return true;
            if (!selectedWorksheetColumn) return true;
        }

        return false;
    }, [selectedType, selectedColumns, selectedWorksheet, selectedWorksheetColumn]);

    const getWorksheets = async () => {
        const body = { action: 'list_worksheets' };
        const data = await fetchWorksheetData(userData.accessToken, body);
  
        if (data.error) {
            console.error('Error fetching worksheets', data.error);
        } else {
            const options = (data.metadata || [])
                .filter((o) => !o.isFolder && o.id !== currentWorksheetId)
                .map((o) => (
                    { label: ((o.folder ? (o.folder + '/') : '') + o.name), value: o.id, folder: o.folder }
                ));
            setWorksheetOptions(options);
        }
    };

    const getWorksheetColumns = async (worksheetId, folder) => {
        const body = { 
            action: 'list_worksheet_columns',
            worksheet_id: worksheetId,
            folder: folder
        };
        const data = await fetchWorksheetData(userData.accessToken, body);
  
        if (data.error) {
            console.error('Error fetching worksheets', data.error);
        } else {
            const options = (data.cols || []).map((o) => ({ label: o.name, value: o.key }));
            setWorksheetColumnOptions(options);
        }
    };

    const selectWorksheet = (value) => {
        setWorksheetColumnOptions(null);
        setSelectedWorksheet(value);
        setSelectedWorksheetColumn(null);
    };

    // Add another column select
    const addColumnSelect = () => {
        setSelectedColumns(prev => [...prev, null]);
    };

    // Update one of the column selects in our array
    const updateColumnAtIndex = (index, val) => {
        const newCols = [...selectedColumns];
        newCols[index] = val;
        setSelectedColumns(newCols);
    };

    useEffect(() => {
        if (!userData.accessToken) return;
        getWorksheets();
    }, [userData]);

    useEffect(() => {
        if (!selectedWorksheet) return;
        const worksheetId = selectedWorksheet;
        // get folder from previously fetched worksheet options
        const folderOption = worksheetOptions.find((o) => o.value === worksheetId);
        // folderOption.label might look like "folderName/Worksheet name"
        // but we stored folder as well, so use that property if it exists
        const folder = folderOption?.folder || '';
        getWorksheetColumns(worksheetId, folder);
    }, [selectedWorksheet, worksheetOptions]);

    return (
        <Modal
            title="Add enriched column"
            open={open}
            onOk={handleOk}
            onCancel={onClose}
            width={800}
            okButtonProps={{ disabled: isOkDisabled }}
        >
            <Row gutter={15}>
                <Col span={8}>
                    <List
                        header={<Typography.Title level={5}>Tools</Typography.Title>}
                        bordered
                        style={{height: '100%'}}
                        dataSource={categories}
                        renderItem={(cat) => (
                            <List.Item
                                style={{
                                    cursor: 'pointer',
                                    backgroundColor: 
                                        cat.catName === selectedCategory 
                                        ? '#e6f7ff' 
                                        : 'inherit'
                                }}
                                onClick={() => {
                                    setSelectedCategory(cat.catName);
                                    setSelectedType(null);
                                }}
                            >
                                <List.Item.Meta
                                    avatar={categoryIcons[cat.catName] || null}
                                    title={cat.catName}
                                />
                            </List.Item>
                        )}
                    />
                </Col>

                {/* Right side: Enrichment options + input setup */}
                <Col span={16}>
                    {!selectedType && (
                        <Row gutter={[16, 16]}>
                            {categories
                                .find((c) => c.catName === selectedCategory)
                                ?.items.map((item) => (
                                    <Col span={12} key={item.type}>
                                        <Card 
                                            hoverable 
                                            title={item.name}
                                            onClick={() => handleSelectType(item.type)}
                                        >
                                            <p>{item.description}</p>
                                        </Card>
                                    </Col>
                                ))
                            }
                        </Row>
                    )}

                    {/* CROSS REFERENCE */}
                    {selectedType && enrichmentConfigs[selectedType].category === 'Cross Reference' && (
                        <>
                            <Typography.Text>New column name</Typography.Text>
                            <br />
                            <Input
                                style={{ width: '100%', marginTop: 8 }}
                                value={newColumnName}
                                onChange={(e) => setNewColumnName(e.target.value)}
                            />
                            <br />
                            <br />
                            <Typography.Title level={5} style={{ marginTop: 16 }}>
                                Setup Inputs
                            </Typography.Title>
                            <Typography.Text>Choose the input column(s)</Typography.Text>
                            <br />
                            {selectedColumns.map((col, idx) => (
                                <Select
                                    key={idx}
                                    placeholder="Select a column"
                                    style={{ width: '100%', marginTop: 8 }}
                                    options={
                                        columns
                                            .filter((c) => c.key !== 'rowIndex' && c.key !== 'addColumn')
                                            .map((c) => ({ label: c.name, value: c.key }))
                                    }
                                    onChange={(val) => updateColumnAtIndex(idx, val)}
                                    value={col}
                                />
                            ))}
                            <Button
                                type="dashed"
                                icon={<PlusOutlined />}
                                style={{ marginTop: 8 }}
                                onClick={addColumnSelect}
                            >
                                Add another column
                            </Button>
                        </>
                    )}

                    {/* AI TYPES */}
                    {selectedType && enrichmentConfigs[selectedType].category === 'AI' && (
                        <>
                            <Typography.Text>New column name</Typography.Text>
                            <br />
                            <Input
                                style={{ width: '100%', marginTop: 8 }}
                                value={newColumnName}
                                onChange={(e) => setNewColumnName(e.target.value)}
                            />
                            <br />
                            <br />

                            <Typography.Title level={5} style={{ marginTop: 16 }}>
                                Setup Inputs
                            </Typography.Title>

                            <Typography.Text>Choose the input column(s)</Typography.Text>
                            <br />
                            {selectedColumns.map((col, idx) => (
                                <Select
                                    key={idx}
                                    placeholder="Select a column"
                                    style={{ width: '100%', marginTop: 8 }}
                                    options={
                                        columns
                                            .filter((c) => c.key !== 'rowIndex' && c.key !== 'addColumn')
                                            .map((c) => ({ label: c.name, value: c.key }))
                                    }
                                    onChange={(val) => updateColumnAtIndex(idx, val)}
                                    value={col}
                                />
                            ))}
                            <Button
                                type="dashed"
                                icon={<PlusOutlined />}
                                style={{ marginTop: 8 }}
                                onClick={addColumnSelect}
                            >
                                Add another column
                            </Button>

                            { selectedType === 'associate' && (
                                <>
                                    <br />
                                    <br />
                                    <Typography.Text style={{ marginTop: 15 }}>
                                        Select worksheet to match against
                                    </Typography.Text>
                                    <br/>
                                    <Select 
                                        style={{ width: '100%', marginTop: 8 }}
                                        options={worksheetOptions}
                                        onChange={selectWorksheet}
                                        value={selectedWorksheet}
                                        placeholder="Select a worksheet"
                                    />
                                    <br />
                                    <br />
                                    { selectedWorksheet && !worksheetColumnOptions &&
                                        <LoadingOutlined />
                                    }
                                    { selectedWorksheet && worksheetColumnOptions &&
                                        <>
                                            <Typography.Text style={{ marginTop: 15 }}>
                                                Select column from {
                                                    worksheetOptions.find((o) => o.value === selectedWorksheet)?.label
                                                } to pull values from
                                            </Typography.Text>
                                            <br/>
                                            <Select 
                                                style={{ width: '100%', marginTop: 8 }}
                                                options={worksheetColumnOptions}
                                                onChange={setSelectedWorksheetColumn}
                                                value={selectedWorksheetColumn}
                                                placeholder="Select a column"
                                            />
                                            <br />
                                            <br />
                                            <Typography.Text style={{ marginTop: 15 }}>
                                                Row match AI prompt
                                            </Typography.Text>
                                            <br />
                                            <TextArea 
                                                style={{ width: '100%', marginTop: 8 }}
                                                placeholder="Which retail chain does this store location belong to?"
                                                rows={2}
                                                value={newPrompt}
                                                onChange={(e) => setNewPrompt(e.target.value)}
                                            />
                                            <br />
                                            <br />
                                            <Checkbox 
                                                checked={leaveBlank}
                                                onChange={(e) => setLeaveBlank(e.target.checked)}
                                            >
                                                Leave match blank if AI unsure
                                            </Checkbox>
                                        </>
                                    }
                                </>
                            )}
                        </>
                    )}
                </Col>
            </Row>
        </Modal>
    );
};

export default EnrichmentFlow;