import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Select, Button, Alert, Typography, Skeleton, Modal, Input, Dropdown, Menu, List, Tooltip, Row, Col } from 'antd';
import { formatTimeAgo, uuid4, volumeFormatter } from '../utils';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from "dayjs";

import {
    DeleteOutlined,
    ProductOutlined,
    ArrowLeftOutlined,
    EditOutlined,
    CaretDownOutlined,
    LoadingOutlined,
    UndoOutlined,
    WarningTwoTone,
    FontSizeOutlined,
    FieldBinaryOutlined,
    DatabaseOutlined,
} from "@ant-design/icons";
import DataGrid from './DataGrid';
import { fetchWorksheetData } from "../../endpoints/fetchWorksheetData";
import EnrichmentFlow from "./EnrichmentFlow";

const colorPrimary = '#4fad96';

function CustomEditor({ row, column, onRowChange, onClose, setEditingCell}) {
    const [val, setVal] = useState(row[column.key]);

    function commitEdit() {
        onClose(true);
        //onFinishEditing(val, row.id, column.key);
    }

    return (
        <div
            className="rdg-editor-container"
            style={{ display: 'flex', alignItems: 'center', height: '100%' }}
        >
            <input
                autoFocus
                className="rdg-text-editor"
                style={{ width: '100%', height: '100%', boxSizing: 'border-box', padding: '0 8px' }}
                value={val}
                onChange={e => {
                    const newValue = e.target.value;
                    setVal(newValue);
                    onRowChange({ ...row, [column.key]: newValue });
                }}
                onBlur={commitEdit}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') commitEdit();
                }}
            />
        </div>
    );
}

const WorksheetManager = (props) => {
    const { userData, setHasError } = props;
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [worksheetId, setWorksheetId] = useState(searchParams.get('worksheet_id') || null);
    const [worksheetName, setWorksheetName] = useState("");
    const [lastIndexed, setLastIndexed] = useState(null);
    const [editingWorksheetName, setEditingWorksheetName] = useState(false);
    const [metadata, setMetadata] = useState([]);
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [cursorSelectedCell, setCursorSelectedCell] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(null);
    const [showFailedAlert, setShowFailedAlert] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [newWorksheetName, setNewWorksheetName] = useState(null);

    const [selectedCell, setSelectedCell] = useState({ rowIdx: null, colKey: null });
    const [selectedRows, setSelectedRows] = useState(() => new Set());
    const [selectedCells, setSelectedCells] = useState(new Set());
    const [isSelecting, setIsSelecting] = useState(false);
    const [startCellPos, setStartCellPos] = useState(null);

    const [newColumnModal, setNewColumnModal] = useState(false);
    const [columnTypeModal, setColumnTypeModal] = useState(false);
    const [newEnrichedColumnModal, setNewEnrichedColumnModal] = useState(false);
    const [newColumnName, setNewColumnName] = useState("");
    const [newColumnType, setNewColumnType] = useState("text");
    const [columnsProcessing, setColumnsProcessing] = useState([]);

    const abortControllerRef = useRef(null);
    const apiRef = useRef({});

    const DEFAULT_ROW_COUNT = 20;
    const DEFAULT_COL_COUNT = 10;

    // We'll keep our last "copied" data in a ref.
    const clipboardDataRef = useRef([]); // will hold a 2D array of strings

    const folderParam = searchParams.get('folder') || '';

    ///////////////////////////////////////////////////////////////////////////
    // Column-level actions: rename & remove, used by the header dropdown
    ///////////////////////////////////////////////////////////////////////////
    const renameColumn = useCallback((colKey, newName, newColumType=null) => {
        const newCols = columns.map((c) => {
            if (c.key === colKey) {
                return { ...c, name: newName, column_type: newColumType || c.column_type };
            }
            return c;
        });
        const toReturn = normalizeColumns(newCols);
        setColumns(newCols);
        setHasChanges(true);

        return toReturn;
    }, [columns]);

    const removeColumnByKey = (colKey) => {
        if (!colKey || colKey === 'rowIndex' || colKey === 'addColumn') return;

        const newCols = columns.filter(c => c.key !== colKey);
        const newRows = rows.map(r => {
            const { [colKey]: _, ...rest } = r;
            return rest;
        });

        // remove any bottom rows that are now fully null or empty after column removal
        const newRowsFiltered = newRows.filter(r => Object.values(r).some(v => v !== '' && v !== null && v !== undefined));

        const toReturn = normalizeColumns(newCols);
        setColumns(toReturn);
        setRows(newRowsFiltered);
        saveWorksheet({newCols, newRows: newRowsFiltered});
        setHasChanges(true);
    };

    useEffect(() => {
        
        // this sets the loading state for the worksheet column headers
        
        if (columnsProcessing.length > 0) {
            setColumns(normalizeColumns(columns));
        }
    }, [columnsProcessing]);

    ///////////////////////////////////////////////////////////////////////////
    // Build the left-most rowIndex column
    ///////////////////////////////////////////////////////////////////////////
    const buildRowIndexColumn = useCallback(() => {
        return {
            key: 'rowIndex',
            name: '#',
            width: 70,
            editable: false,
            frozen: true,
            resizable: false,
            sortable: false,
            renderCell: ({ row }) => <span>{volumeFormatter.format(row.id + 1)}</span>,
        };
    }, []);

    ///////////////////////////////////////////////////////////////////////////
    // The final “+ Add column” is always appended at the end
    ///////////////////////////////////////////////////////////////////////////
    const buildAddColumnColumn = useCallback(() => {
        return {
            key: 'addColumn',
            name: '+ Add column',
            editable: false,
            resizable: false,
            draggable: false,
            sortable: false,
            renderHeaderCell: ({ column }) => {
                return (
                    <div style={{ cursor: 'pointer' }}>
                        {column.name}
                        <CaretDownOutlined style={{ fontSize: 12, marginLeft: 6 }} />
                    </div>
                );
            },
            // This column’s cells are always empty
            renderCell: () => ''
        };
    }, []);

    const pollTaskUntilDone = (task_id) => {
        setShowSuccessAlert('Enrichment processing...');
        const startTime = Date.now();

        var counter = 0;

        const doPoll = () => {
            let waitTime;
            // the longer it's been, the less frequently we poll
            // exponential backoff
            if (counter > 6) {
                const elapsed = Date.now() - startTime;

                // max 30s, min 1s
                waitTime = Math.min(30000, Math.max(1000, Math.pow(1.3, elapsed / 1000) * 1000));
            } else {
                // first 6 times, poll every 500ms to get quick feedback on initial 3 row batch
                waitTime = 500;
            }

            fetchWorksheetData(userData.accessToken, {
                action: 'poll_enrichment',
                task_id: task_id
            }).then((result) => {
                if (result.error) {
                    setShowFailedAlert(result.error);
                    if (!result.is_reindex) {
                        setColumnsProcessing(prev => prev.filter((c) => c !== result['destination_col']));
                        setColumns(prev => normalizeColumns(prev.map((c) => {
                            if (c.key === result['destination_col']) {
                                return { ...c, error: result.error };
                            }
                            return c;
                        })));
                    } else {
                        setColumns(prev => normalizeColumns(prev.map((c) => {
                            return { ...c, error: result.error };
                        })));
                        setColumnsProcessing(prev => []);
                    }

                    return;
                } else if (result.status === 'complete') {  
                    
                    if (!result.is_reindex) {
                        setColumnsProcessing(prev => prev.filter((c) => c !== result['destination_col']));
                    } else {
                        setColumnsProcessing(prev => []);
                    }

                    if (!result.is_reindex) {
                        setColumns(prev => prev.map((c) => {
                            if (c.key === result['destination_col']) {
                                return result.worksheet_data.data.cols.find((col) => col.key === result['destination_col']);
                            }
                            return c;
                        }));
                    } else {
                        setColumns(normalizeColumns(columns));
                    }

                    if (!result.is_reindex) {
                        setRows(result['worksheet_data']['data'].rows);
                    }
                    setHasChanges(false);

                    if (result.is_reindex)
                        setLastIndexed(result.last_indexed_at);

                    setShowSuccessAlert('Enrichment complete');

                    return;
                } else if (result.status === 'incomplete') {
                    if (!result.is_reindex) {

                        setRows(prev => prev.map((r, idx) => {
                            return {...r, [result['destination_col']]: result['partial_column_data'][idx]};
                        }));
                    }

                }

                // not done yet: schedule next check
                setTimeout(doPoll, waitTime);
            });
            }
        
        doPoll();
    }

    ///////////////////////////////////////////////////////////////////////////
    // Build the actual columns from server data, with:
    //   - custom editor
    //   - a header dropdown that has Rename & Delete
    ///////////////////////////////////////////////////////////////////////////
    const normalizeColumns = useCallback(
        (cols, newRows = null) => {
            const allCols = cols.map((c) => {
                // If it's the rowIndex or addColumn, just return it
                if (c.key === 'rowIndex') {
                    return buildRowIndexColumn();
                } else if (c.key === 'addColumn') {
                    return buildAddColumnColumn();
                }

                return {
                    ...c,
                    editable: !(columnsProcessing.includes(c.key) || c.key === 'addColumn' || c.key === 'rowIndex'),
                    resizable: c.resizable !== false,
                    draggable: c.draggable !== false,
                    column_type: c.column_type,
                    minWidth: 200,
                    renderEditCell: (editProps) => (
                        <CustomEditor
                            {...editProps}
                            rows={newRows || rows}
                            columns={cols || columns}
                        />
                    ),
                    sortable: c.sortable !== false,
                    renderHeaderCell: ({ column }) => {
                        let columnTypeIcon = undefined;
                        if (column.column_type === 'text') {
                            // light grey
                            columnTypeIcon = <FontSizeOutlined style={{color: '#918d8d'}} />;
                        }
                        else if (column.column_type === 'number') {
                            columnTypeIcon = <FieldBinaryOutlined style={{color: '#918d8d'}} />;
                        }
                        else if (column.column_type === 'product') {
                            columnTypeIcon = <ProductOutlined style={{color: '#918d8d'}} />;
                        }
                        return (
                            // name far left, processing loading outlined far right if in processing state
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>
                                    <span style={{ marginRight: 8 }}>
                                        {columnTypeIcon}
                                    </span>
                                    {column.name}
                                </div>
                                {columnsProcessing.includes(column.key) && (
                                    <LoadingOutlined style={{ fontSize: 16, color: colorPrimary }} />
                                )}
                                { column.error && (
                                    <Tooltip title={column.error}>
                                        <WarningTwoTone twoToneColor="#ff0000" style={{ fontSize: 16 }} />
                                    </Tooltip>
                                )
                                }
                            </div>
                        )
                    },
                };
            });

            let finalCols = allCols;

            // add the final "Add column" column if not already there
            if (allCols.length === 0 || allCols[allCols.length - 1].key !== 'addColumn') {
                finalCols = [...allCols, buildAddColumnColumn()];
            }

            // add the rowIndex column if not already there
            if (allCols.length === 0 || allCols[0].key !== 'rowIndex') {
                finalCols = [buildRowIndexColumn(), ...finalCols];
            }

            return finalCols;
        },
        [rows, columns, removeColumnByKey, renameColumn, columnsProcessing]
    );

    ///////////////////////////////////////////////////////////////////////////
    // Load the worksheet from server
    ///////////////////////////////////////////////////////////////////////////
    const loadWorksheet = useCallback(
        async (id) => {
            setLoading(true);
            setShowFailedAlert(null);
            setShowSuccessAlert(null);
            setHasChanges(false);

            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
            abortControllerRef.current = new AbortController();

            try {
                const body = {
                    worksheet_id: id ? id : undefined,
                    folder: folderParam
                };
                const data = await fetchWorksheetData(userData.accessToken, body, {
                    signal: abortControllerRef.current.signal
                });

                if (data.error) {
                    setShowFailedAlert(data.error);
                    setLoading(false);
                    return;
                }

                let worksheetData = data.data;
                let activeMeta = data.metadata;

                setMetadata(activeMeta || []);

                const finalCols = normalizeColumns(worksheetData.cols || []);
                const updatedRows = worksheetData.rows || [];
                setWorksheetName(activeMeta.name);
                setNewWorksheetName(activeMeta.name);
                setLastIndexed(activeMeta.last_indexed_at);

                let allCols = normalizeColumns(
                    [...finalCols.filter((c) => !c.frozen)],
                    updatedRows
                );

                setColumnsProcessing(activeMeta ? activeMeta.processing_col_keys : []);

                (activeMeta.processing_tasks || []).forEach((taskId) => {
                    // start polling for incomplete tasks
                    pollTaskUntilDone(taskId);
                });

                setRows(updatedRows);
                setColumns(allCols);
            } catch (e) {
                if (e.name !== 'AbortError') {
                    setHasError(e);
                    console.error(e);
                }
            } finally {
                setLoading(false);
            }
        },
        [
            userData.accessToken,
            setHasError,
            navigate,
            buildRowIndexColumn,
            buildAddColumnColumn,
            normalizeColumns
        ]
    );

    useEffect(() => {
        if (showSuccessAlert) {
            const timer = setTimeout(() => {
                setShowSuccessAlert(null);
            }, 5000);
            return () => clearTimeout(timer);
        }
        if (showFailedAlert) {
            const timer = setTimeout(() => {
                setShowFailedAlert(null);
            }, 60000);
            return () => clearTimeout(timer);
        }
    }, [showSuccessAlert, showFailedAlert]);

    useEffect(() => {
        loadWorksheet(worksheetId);
    }, []);

    const handleSelectedCellChange = useCallback((selectedCells) => {
        if (!selectedCell || !selectedCells.row) return;

        const rowIdx = selectedCells.row.id;
        const colKey = selectedCells.column.key;

        setCursorSelectedCell({ rowIdx, colKey });
    }, [selectedCell]);

    ///////////////////////////////////////////////////////////////////////////
    // Save current worksheet to server
    ///////////////////////////////////////////////////////////////////////////
    const saveWorksheet = async ({ newNameForSave = null, newCols = null, newRows = null } = {}) => {
        setSaving(true);
        setShowFailedAlert(null);
        setShowSuccessAlert(null);
        const rowsNow = newRows ? newRows : [...rows];
        const colsNow = newCols ? newCols : [...columns];

        try {
            const body = {
                action: 'save',
                name: worksheetName,
                newWorksheetName: newNameForSave,
                worksheet_id: worksheetId ? worksheetId : null,
                folder: folderParam,
                data: { rows: rowsNow, cols: colsNow }
            };
            const result = await fetchWorksheetData(userData.accessToken, body);

            if (result.error) {
                setShowFailedAlert(result.error);
            } else {
                setShowSuccessAlert('Worksheet saved');
                setHasChanges(false);
            }
        } catch (e) {
            setShowFailedAlert(e.message);
        } finally {
            setSaving(false);
        }
    };

    ///////////////////////////////////////////////////////////////////////////
    // Row and Column “add/remove” from the old File menu
    ///////////////////////////////////////////////////////////////////////////
    const addRow = useCallback(() => {
        const newRow = { id: rows.length };
        columns.forEach((c) => {
            if (c.key !== 'select-row') {
                newRow[c.key] = "";
            }
        });
        const newRows = [...rows, newRow];
        setRows(newRows);
        setHasChanges(true);
        
        saveWorksheet({newRows});
        return newRows;
    }, [rows, columns]);

    const removeRow = useCallback(() => {
        if (
            selectedCell.rowIdx == null ||
            selectedCell.rowIdx < 0 ||
            selectedCell.rowIdx >= rows.length
        ) {
            return;
        }
        const newRows = rows.filter((_, idx) => idx !== selectedCell.rowIdx);
        newRows.forEach((r, i) => (r.id = i));
        setRows(newRows);
        setHasChanges(true);
        saveWorksheet({newRows});
    }, [rows, selectedCell]);

    const addColumn = useCallback((colName, config={}) => {
        // uuidv4() for new key
        const newKey = uuid4();
        const newCol = {
            key: newKey,
            name: colName,
            editable: true,
            resizable: true,
            draggable: true,
            sortable: true,
            ...config
        };
        // slice out the final column
        const existingColsNoAdd = columns.slice(1, columns.length - 1);
        const normalizedNewCols = normalizeColumns([...existingColsNoAdd, newCol]);
        const newRows = rows.map((r) => ({ ...r, [newKey]: "" }));
        setColumns(normalizedNewCols);
        setRows(newRows);
        setHasChanges(true);

        return normalizedNewCols;
    }, [columns, rows, normalizeColumns, buildAddColumnColumn]);

    const removeColumn = () => {
        if (!selectedCell.colKey || selectedCell.colKey === 'select-row') return;
        const removedKey = selectedCell.colKey;
        if (removedKey === 'rowIndex' || removedKey === 'addColumn') return;
        const newCols = columns.filter((c) => c.key !== removedKey);
        const newRows = rows.map((r) => {
            const { [removedKey]: _, ...rest } = r;
            return rest;
        });
        setColumns(newCols);
        setRows(newRows);
        saveWorksheet({newCols, newRows});
        setHasChanges(true);
    }

    const deleteWorksheet = async () => {
        if (!worksheetId) {
            setShowFailedAlert("No worksheet selected to delete");
            return;
        }
        const body = {
            action: 'delete',
            folder: folderParam,
            worksheet_id: worksheetId
        };
        try {
            setShowSuccessAlert('Deleting...');
            const result = await fetchWorksheetData(userData.accessToken, body);
            if (result.error) {
                setShowFailedAlert(result.error);
            } else {
                setWorksheetId(null);
                loadWorksheet(null);
                navigate('/orchestrate/home');
            }
        } catch (e) {
            setShowFailedAlert(e.message);
        }
    };

    // called by the custom editor when a cell is done being edited
    const onRowsChange = (newRows) => {
        setRows(newRows);
        saveWorksheet({newRows});
        setHasChanges(true);
    };

    function onColumnsReorder(sourceKey, targetKey) {
        const sourceIndex = columns.findIndex((c) => c.key === sourceKey);
        const targetIndex = columns.findIndex((c) => c.key === targetKey);
        if (sourceIndex === -1 || targetIndex === -1) return;

        // Make a copy of columns
        const newCols = [...columns];

        // Remove the dragged column
        const [movedColumn] = newCols.splice(sourceIndex, 1);

        // Insert it at the new position
        newCols.splice(targetIndex, 0, movedColumn);

        setColumns(newCols);
        setHasChanges(true);
    }

    function rowKeyGetter(row) {
        return row ? row.id : -1;
    }

    function commitWorksheetName() {
        setEditingWorksheetName(false);
        setWorksheetName(newWorksheetName);
        saveWorksheet({newNameForSave: newWorksheetName});
    }

    ///////////////////////////////////////////////////////////////////////////
    // Some selection helpers
    ///////////////////////////////////////////////////////////////////////////
    function getSelectionBounds(selCells) {
        if (selCells.size === 0) return null;
        let minRow = Infinity,
            maxRow = -Infinity;
        let minCol = Infinity,
            maxCol = -Infinity;

        for (const cellStr of selCells) {
            const { r, c } = JSON.parse(cellStr);
            const colIndex = columns.findIndex((col) => col.key === c);
            if (r < minRow) minRow = r;
            if (r > maxRow) maxRow = r;
            if (colIndex < minCol) minCol = colIndex;
            if (colIndex > maxCol) maxCol = colIndex;
        }

        return { minRow, maxRow, minCol, maxCol };
    }

    function buildTsvString(data2D) {
        return data2D
            .map((row) =>
                row
                    .map((cell) => {
                        const str = (cell ?? '').toString();
                        return str.replace(/\t/g, '    ');
                    })
                    .join('\t')
            )
            .join('\r\n');
    }

    function parseTsv(text) {
        const lines = text.split(/\r?\n/).filter((line) => line !== '');
        return lines.map((line) => line.split('\t'));
    }

    function parseClipboardData(text) {
        if (text.includes('\t')) {
            // parse as TSV
            return parseTsv(text);
        } else {
            const lines = text.split(/\r?\n/).filter((line) => line !== '');
            return lines.map((line) => [line]);
        }
    }

    async function copySelectionToClipboard(cut = false) {
        const bounds = getSelectionBounds(selectedCells);
        if (!bounds) return;
        const { minRow, maxRow, minCol, maxCol } = bounds;

        const data = [];
        for (let r = minRow; r <= maxRow; r++) {
            const rowArr = [];
            for (let c = minCol; c <= maxCol; c++) {
                const colKey = columns[c]?.key;
                rowArr.push(rows[r]?.[colKey] ?? '');
            }
            data.push(rowArr);
        }

        clipboardDataRef.current = data;

        const tsvString = buildTsvString(data);

        if (window.isSecureContext && navigator.clipboard) {
            try {
                await navigator.clipboard.writeText(tsvString);
            } catch (err) {
                console.warn('Clipboard write failed; using local data only:', err);
            }
        }

        if (cut) {
            const updatedRows = [...rows];
            for (let r = minRow; r <= maxRow; r++) {
                for (let c = minCol; c <= maxCol; c++) {
                    const colKey = columns[c]?.key;
                    if (updatedRows[r] && colKey in updatedRows[r]) {
                        updatedRows[r][colKey] = '';
                    }
                }
            }
            setRows(updatedRows);
            setHasChanges(true);
        }
    }

    async function pasteFromClipboard() {
        let text = null;
        if (window.isSecureContext && navigator.clipboard) {
            try {
                text = await navigator.clipboard.readText();
            } catch (err) {
                console.warn('Clipboard read failed; using local data:', err);
            }
        }

        let data2D;
        if (text) {
            data2D = parseClipboardData(text);
        } else {
            data2D = clipboardDataRef.current;
            if (!data2D || data2D.length === 0) {
                return;
            }
        }

        const bounds = getSelectionBounds(selectedCells);
        let startRow = 0;
        let startCol = 1;
        if (cursorSelectedCell) {
            startRow = cursorSelectedCell.rowIdx;
            startCol = columns.findIndex((c) => c.key === cursorSelectedCell.colKey);
        } else if (bounds) {
            startRow = bounds.minRow;
            startCol = bounds.minCol;
        }

        const updatedRows = [...rows];
        for (let r = 0; r < data2D.length; r++) {
            const rowIndex = startRow + r;
            if (rowIndex >= updatedRows.length) {
                const newRow = { id: updatedRows.length };
                columns.forEach((col) => {
                    if (col.key !== 'rowIndex') {
                        newRow[col.key] = '';
                    }
                });
                updatedRows.push(newRow);
            }
            for (let c = 0; c < data2D[r].length; c++) {
                const colIndex = startCol + c;
                if (colIndex < columns.length) {
                    const colKey = columns[colIndex].key;
                    updatedRows[rowIndex][colKey] = data2D[r][c];
                }
            }
        }

        updatedRows.forEach((row, idx) => (row.id = idx));
        setRows(updatedRows);

        setColumns(normalizeColumns(columns, updatedRows));
        setShowSuccessAlert(`Pasted ${data2D.length} rows, ${data2D[0].length} columns`);
        setHasChanges(true);
    }

    function onCellClick({ column, row }) {
        const rowIdx = row.id;
        setSelectedCell({ rowIdx, colKey: column.key });
    }

    apiRef.current.selectCellRange = (start, end, keepOtherSelected = false) => {
        const startRow = Math.min(start.rowIdx, end.rowIdx);
        const endRow = Math.max(start.rowIdx, end.rowIdx);
        const startColIndex = columns.findIndex((c) => c.key === start.colKey);
        const endColIndex = columns.findIndex((c) => c.key === end.colKey);

        const minCol = Math.min(startColIndex, endColIndex);
        const maxCol = Math.max(startColIndex, endColIndex);

        const newSelected = new Set(keepOtherSelected ? selectedCells : []);
        for (let r = startRow; r <= endRow; r++) {
            for (let c = minCol; c <= maxCol; c++) {
                const cellKey = JSON.stringify({ r, c: columns[c].key });
                newSelected.add(cellKey);
            }
        }
        setSelectedCells(newSelected);
    };

    const buildDataSource = async () => {
        // action is a type of "enrichment" on backend
        const allColKeys = columns.filter(
            (c) => c.key !== 'rowIndex' && c.key !== 'addColumn'
        ).map((c) => c.key);

        setColumnsProcessing([...allColKeys]);

        const body = {
            action: 'enrichment',
            worksheet_id: worksheetId,
            folder: folderParam,
            type: 'data_source_conversion',
            sourceCols: allColKeys, // all column should show as "processing" until conversion complete (index namespace built and populated)
            data: { rows, cols: columns }
        };

        fetchWorksheetData(userData.accessToken, body).then((result) => {
            if (result.error) {
                setShowFailedAlert(result.error);
            } else {
                pollTaskUntilDone(result.task_id);
                setHasChanges(false);
        }});
    }
    

    const worksheetActionMenu = (
        <Menu
            onClick={(info) => {
                if (info.key === 'add_row') addRow();
                else if (info.key === 'remove_row') removeRow();
                else if (info.key === 'remove_col') removeColumn();
                else if (info.key === 'data_source') buildDataSource()
                else if (info.key === 'delete') deleteWorksheet();
            }}
        >
            <Menu.Item key="add_row">+ Add bottom row</Menu.Item>
            <Menu.Item key="remove_row" disabled={selectedCell.rowIdx == null}>
                Remove selected row
            </Menu.Item>
            <Menu.Item
                key="remove_col"
                disabled={
                    !selectedCell.colKey ||
                    selectedCell.colKey === 'select-row' ||
                    selectedCell.colKey === 'rowIndex' ||
                    selectedCell.colKey === 'addColumn'
                }
            >
                Remove selected column
            </Menu.Item>
            <Menu.Item key="data_source">
                Train AI on worksheet
            </Menu.Item>
            <Menu.Item key="delete" disabled={!worksheetId} danger>
                Delete Worksheet
            </Menu.Item>
        </Menu>
    );

    const gridRef = useRef(null);
    const shadowGridRef = useRef(null);

    const handleNewEnrichedColumn = async (type, colKeys, config) => {
        setNewEnrichedColumnModal(false);
        const configCopy = { ...config };

        const allNewColumns = addColumn(configCopy['column_name'], config={'column_type': configCopy['column_type'], 'inputCols': configCopy['inputCols']});
        const newCol = allNewColumns[allNewColumns.length - 2];

        setColumnsProcessing([...columnsProcessing.filter((c) => colKeys.includes(c)), newCol.key]);

        const newRows = rows;

        // only include non-pseudo columns
        const newCols = [...columns, newCol].filter((c) => c.key !== 'rowIndex' && c.key !== 'addColumn');
        const data = {
            rows: newRows,
            cols: newCols
        }

        const body = {
            ...configCopy,
            action: 'enrichment',
            worksheet_id: worksheetId,
            folder: folderParam,
            type: type,
            sourceCols: colKeys,
            destinationCol: newCol.key,
            data: data
        };

        // send to backend for processing and new column generation
        fetchWorksheetData(userData.accessToken, body).then((result) => {
            if (result.error) {
                setShowFailedAlert(result.error);
            } else {
                pollTaskUntilDone(result.task_id);
                setHasChanges(false);
            }
        }
        )
    }

    const getCellFromMouseEvent = (e) => {
        const shadow = shadowGridRef.current?.getShadowRoot();
        let realTarget = e.target;
        if (shadow && !shadow.contains(e.target)) {
            realTarget = shadow.elementFromPoint(e.clientX, e.clientY);
        }
        const cellEl = realTarget?.closest('.rdg-cell');
        if (!cellEl) return null;

        const colIdx = parseInt(cellEl.getAttribute('aria-colindex')) - 1;
        const colKey = columns[colIdx].key;

        let rowIdx;
        const rowEl = realTarget.closest('.rdg-row');
        if (!rowEl) {
            // header
            rowIdx = -1;
        } else {
            const offset = 2;
            rowIdx = parseInt(rowEl.getAttribute('aria-rowindex'), 10) - offset;
        }
        return { rowIdx, colKey };
    };

    // const handleMouseDown = (e) => {
    //     const cell = getCellFromMouseEvent(e);
    //     if (!cell) return;

    //     const { rowIdx, colKey } = cell;
    //     setIsSelecting(true);

    //     console.log('Mouse down:', cell);
    //     if (rowIdx < 0) {
    //         // user clicked a header
    //         const start = { rowIdx: 0, colKey };
    //         const end = { rowIdx: rows.length - 1, colKey };
    //         apiRef.current.selectCellRange(start, end, false);
    //         setSelectedCell({ rowIdx: 0, colKey: colKey });
            
    //         if (colKey === 'addColumn') {
    //             setColumnTypeModal(true);
    //         } else setNewColumnModal(true);

    //         return;
    //     }

    //     if (colKey === 'rowIndex') {
    //         // user clicked row index
    //         const firstDataCol = columns[1].key;
    //         const lastDataCol = columns[columns.length - 1].key;
    //         const start = { rowIdx, colKey: firstDataCol };
    //         const end = { rowIdx, colKey: lastDataCol };
    //         apiRef.current.selectCellRange(start, end, false);
    //         return;
    //     }

    //     setStartCellPos(cell);
    //     apiRef.current.selectCellRange(cell, cell, false);
    // };

    const handleMouseUp = (e) => {
        const cell = getCellFromMouseEvent(e);
        if (!cell) return;

        const { rowIdx, colKey } = cell;
        setIsSelecting(true);

        if (rowIdx < 0) {
            // user clicked a header
            const start = { rowIdx: 0, colKey };
            const end = { rowIdx: rows.length - 1, colKey };
            apiRef.current.selectCellRange(start, end, false);
            setSelectedCell({ rowIdx: 0, colKey: colKey });
            
            if (colKey === 'addColumn') {
                setColumnTypeModal(true);
                setNewColumnName('');
                setNewColumnType('text');
            } else {
                console.log(columns.find((c) => c.key === colKey)?.name)
                setNewColumnName(columns.find((c) => c.key === colKey)?.name);
                setNewColumnType(columns.find((c) => c.key === colKey)?.column_type);
                setNewColumnModal(true);
            }

            return;
        }

        if (colKey === 'rowIndex') {
            // user clicked row index
            const firstDataCol = columns[1].key;
            const lastDataCol = columns[columns.length - 1].key;
            const start = { rowIdx, colKey: firstDataCol };
            const end = { rowIdx, colKey: lastDataCol };
            apiRef.current.selectCellRange(start, end, false);
            return;
        }

        setStartCellPos(cell);
        apiRef.current.selectCellRange(cell, cell, false);
    };

    const handleKeyDown = async (e) => {
        const ctrlOrCmd = e.ctrlKey || e.metaKey;

        // Copy
        if (ctrlOrCmd && e.key.toLowerCase() === 'c') {
            e.preventDefault();
            e.stopPropagation();
            await copySelectionToClipboard(false);
            return;
        }

        // Paste
        if (ctrlOrCmd && e.key.toLowerCase() === 'v') {
            e.preventDefault();
            e.stopPropagation();
            await pasteFromClipboard();
            return;
        }
    };

    return (
        <div style={{ height: 'calc(100vh - 58px)', display: 'flex', flexDirection: 'column', padding: 15 }}>
            {rows.length > 100000 && (
                <Alert
                    message={`Worksheet not saved - over 100,000 row limit`}
                    type="error"
                    showIcon
                    style={{
                        position: 'absolute',
                        top: 65,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 1000,
                        width: '30%'
                    }}
                />
            )}
            {showSuccessAlert && (
                <Alert
                    message={showSuccessAlert}
                    type="success"
                    showIcon
                    style={{
                        position: 'absolute',
                        bottom: 10,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 1000,
                        width: '25%'
                    }}
                />
            )}
            {showFailedAlert && (
                <Alert
                    message={showFailedAlert}
                    type="error"
                    showIcon
                    closable
                    style={{
                        position: 'absolute',
                        bottom: 10,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 1000,
                        width: '25%'
                    }}
                />
            )}

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, gap: 10 }}>
                <div style={{ fontSize: '1.2em', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                    <Button type="link" onClick={() => navigate('/orchestrate/home')}>
                        <ArrowLeftOutlined />
                        Back
                    </Button>
                    {loading ? (
                        <Skeleton.Input style={{ width: 200 }} active />
                    ) : editingWorksheetName ? (
                        <Input
                            value={newWorksheetName}
                            onChange={(e) => setNewWorksheetName(e.target.value)}
                            autoFocus
                            onBlur={commitWorksheetName}
                            onPressEnter={commitWorksheetName}
                            style={{ width: 200 }}
                        />
                    ) : (
                        <div
                            style={{
                                border: '1px solid #d9d9d9',
                                borderRadius: 5,
                                padding: 5
                            }}
                            onClick={() => setEditingWorksheetName(true)}
                        >
                            <span style={{ marginRight: 15 }}>{worksheetName}</span>
                            <EditOutlined style={{ fontSize: '12px', cursor: 'pointer' }} />
                        </div>
                    )}
                </div>

                <div style={{ flex: 1 }}></div>
                <Typography.Text
                    style={{color: 'grey'}}
                >AI last trained: {lastIndexed ? formatTimeAgo(lastIndexed) : 'never'}</Typography.Text>
                <Dropdown overlay={worksheetActionMenu} trigger={['click']}>
                    <Button>Actions <CaretDownOutlined /></Button>
                </Dropdown>
                <Button type='primary' onClick={() => setNewEnrichedColumnModal(true)} icon={<FontAwesomeIcon icon={faWandMagicSparkles}/>} >Add enrichment</Button>
            </div>

            <div
                ref={gridRef}
                tabIndex={0}
                onKeyDownCapture={handleKeyDown}
                //onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                style={{ flex: 1, position: 'relative', height: 'calc(100vh - 10%)', overflow: 'auto' }}
            >
                {loading ? (
                    <div style={{ padding: 15 }}>
                        <Skeleton active paragraph={{ rows: 15 }} />
                    </div>
                ) : (
                    <DataGrid
                        ref={shadowGridRef}
                        rowKeyGetter={rowKeyGetter}
                        onSelectedCellChange={handleSelectedCellChange}
                        columns={columns}
                        rows={rows}
                        onRowsChange={onRowsChange}
                        onColumnsReorder={onColumnsReorder}
                        className="rdg-light"
                        onCellClick={onCellClick}
                        selectedRows={selectedRows}
                        onSelectedRowsChange={setSelectedRows}
                    />
                )}
            </div>
            

            <Modal
                title="Choose column type"
                open={columnTypeModal}
                onCancel={() => setColumnTypeModal(false)}
            >
                <List
                    dataSource={[
                        { name: 'Enriched column', value: 'enriched' },
                        { name: 'Data column', value: 'data' },
                    ]}
                    onClick={(item) => {
                        const span = item.target;
                        const spanChildValue = span.childNodes[0].textContent;
   
                        if (spanChildValue === 'Enriched column') {
                            setNewEnrichedColumnModal(true);
                        } else {
                            setNewColumnModal(true);
                        }
                        setColumnTypeModal(false);
                    }}
                    renderItem={(item) => (
                        <List.Item style={{ cursor: 'pointer' }}>
                            <Typography.Text>{item.name}</Typography.Text>
                        </List.Item>
                    )}
                >

                </List>

            </Modal>

            <Modal
                title={selectedCell.colKey !== 'addColumn' ? `Edit "${columns.find((c) => c.key === selectedCell.colKey)?.name}" column` : 'Add column'}
                open={newColumnModal}
                footer={null}
                onCancel={() => setNewColumnModal(false)}
            >
                {selectedCell.colKey === 'addColumn' && (
                    <>
                    <Typography.Text>Column name</Typography.Text>
                    <Input
                        style={{ marginBottom: 15 }}
                        value={newColumnName}
                        onChange={(e) => {
                            const newName = e.target.value;
                            setNewColumnName(newName);
                        }}
                    />
                    <Select 
                        placeholder="Select type"
                        style={{ width: 400 }}
                        value={newColumnType}
                        onChange={(value) => setNewColumnType(value)}
                        options={[
                            { label: 'Text', value: 'text' },
                            { label: 'Number', value: 'number' },
                        ]}
                    />
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 40 }}>
                            <div></div>
                            <div>
                                <Button style={{marginRight: 15}} onClick={() => {
                                    setNewColumnModal(false);
                                }}>
                                    Cancel
                                </Button>
                                <Button type="primary" onClick={() => {
                                    let newCols;
                                    if (selectedCell.colKey !== 'addColumn') {
                                        newCols = renameColumn(selectedCell.colKey, newColumnName, newColumnType);
                                        
                                    } else {
                                        newCols = addColumn(newColumnName, {column_type: newColumnType});
                                    }   
                                    setNewColumnModal(false);
                                    saveWorksheet({newCols});
                                }
                                }>
                                    Add column
                                </Button>
                            </div>
                        </div>
                    </>
                )}
                {selectedCell.colKey !== 'addColumn' && (
                    <>
                    <Typography.Text>Change name</Typography.Text>
                    <Input
                        style={{ marginBottom: 15 }}
                        value={newColumnName}
                        onChange={(e) => {
                            const newName = e.target.value;
                            setNewColumnName(newName);
                        }}
                    />
                    
                    <Typography.Text>Change Type</Typography.Text>
                    <Select 
                        placeholder="Select type" 
                        style={{ width: 400 }}
                        value={newColumnType}
                        onChange={(value) => setNewColumnType(value)}
                        options={[
                            { label: 'Text', value: 'text' },
                            { label: 'Number', value: 'number' },
                        ]}
                    />
      
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 40 }}>
                            <Button danger onClick={() => {
                                removeColumnByKey(selectedCell.colKey);
                                setNewColumnModal(false);
                            }}>
                                <DeleteOutlined />
                                Remove column
                            </Button>
                            <div>
                                <Button style={{marginRight: 15}} onClick={() => {
                                    setNewColumnModal(false);
                                }}>
                                    Cancel
                                </Button>
                                <Button type="primary" onClick={() => {
                                    let newCols;
                                    if (selectedCell.colKey !== 'addColumn') {
                                        newCols = renameColumn(selectedCell.colKey, newColumnName, newColumnType);
                                    } else {
                                        newCols = addColumn(newColumnName, {column_type: newColumnType});
                                    }   
                                    setNewColumnModal(false);
                                    
                                    saveWorksheet({newCols});
                                }
                                }>
                                    Ok
                                </Button>
                            </div>
                        </div>
                    </> 
                )}

            </Modal>
            <EnrichmentFlow
                userData={userData}
                open={newEnrichedColumnModal}
                currentWorksheetId={worksheetId}
                onClose={() => setNewEnrichedColumnModal(false)}
                onOk={handleNewEnrichedColumn}
                columns={columns}
            />
        </div>
    );
};

export default WorksheetManager;